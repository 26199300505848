$primary-nav-width: 13.5%; /*14.27%;*/ /* 16.66667%;*/

#primary-nav .level-1 {
    width: $primary-nav-width; //Customize me based on content!

    a.external-link:after {
        content: '\e811';
        padding-left: 5px;
        @include fontello();
    }

    padding: 8px 3px;
    &:nth-child(2) {
    	width: 16%;
    }

    @include breakpoint($md) {
    	width: 13.5%;
	    &:nth-child(2) {
	    	width: 19%;
	    }
    }
}

#drawer-nav .level-2 {
    @include breakpoint($sm) {
        padding-right: 10px;
    }
}

#drawer-nav .level-4 {
    display: none;
}

#active-second-level-nav a.external-link:after {
    content: '\e811';
    padding-left: 5px;
    @include fontello();
}
#active-second-level-nav>.menu {
    padding-left: 0;
}

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 22px;
        line-height: 2;
    }
}

.brand__lockup img {
    height: 84px;
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

#block-menu-block-2 {
    max-width: 1140px;
    // padding-left: 20px;
    // padding-right: 20px;
    padding: 0 1em;

    margin: 25px 20px 25px;
    font-size: 12px;
    line-height: 28px;
    position: relative;
    border: 1px solid $wcm-border-gray;
    border-radius: 10px;
    color: $wcm-med-gray;

    h2 {
        font-size: 14px;
        margin: 14px 0 10px;
        text-align: center;
        color: $wcm-dark-orange;
        cursor: pointer;

        &:after {
            content: "\e80a";
            @include fontello();
            width: 45px;
            display: block;
            position: absolute;
            top: 8px;
            right: 0;
            font-size: 20px;
        }

        &.menu-active:after {
            content: "\e809";
        }
    }

    .content {
        display: none;
        margin: 0;
        text-align: center;

        ul {
            padding: 0;
            margin: 0 0 5px 0;
        }

        a {
            color: $wcm-med-gray;
        }
    }

    @include breakpoint($sm) {
        display: none;
    }
}

@mixin icon-after($character, $color: $wcm-dark-orange) {
  &:after {
    color: $color;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: 15px;
    display: inline-block;
    text-decoration: inherit;
    //    width: 1em;
    margin-right: .2em;
    padding-left: 3px;
    text-align: center;

    font-variant: normal;
    text-transform: none;

    line-height: 1em;

    //    margin-left: .2em;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: $character;
  }
  &:hover, &:active {
    &:after {
      text-decoration: none;
    }
  }
}

.information-sidebar {
    font-size: 13px;

    h3, h4 {
        font-size: 16px;
        color: $wcm-dark-gray;
    }

    h5 {
        font-size: inherit;
        color: $wcm-black;
        font-weight: 600;
        font-family: "Open Sans",sans-serif;
    }
}

tbody {
    border: none;
}

.addthis_toolbox {
    margin-bottom: 10px;

    .at-icon-wrapper {
        display: none;
    }
    .btn-share {
//        border: 1px solid $wcm-bright-orange;
        border-radius: 50%;
        margin-right: 10px;
        width: 22px !important;
        height: 22px;
    }

    .icon-facebook {
        @include icon-after('\e804', $wcm-bright-orange);
        &:after {
//            padding-left: 8px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
    .icon-twitter {
        @include icon-after('\e807', $wcm-bright-orange);
        &:after {
            padding-left: 0px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
    .icon-linkedin {
        @include icon-after('\e806', $wcm-bright-orange);
        &:after {
//            padding-left: 7px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
    .icon-email {
        @include icon-after('\e80c', $wcm-bright-orange);
        &:after {
//            padding-left: 5px;
            font-size: 16px;
            line-height: 1.8em;
        }
    }
}

.view-news {

    .views-exposed-form {
        .widget-label {
          display: inline-block;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
        }
      }

    .view-teaser {
        @include clearfix();
        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .teaser-title {
        margin-top: 0;
    }
    .teaser-image {
        width: 140px;
        height: 140px;
        overflow: hidden;
        margin-top: .25em;
        margin-bottom: 5px;

        @include breakpoint($sm){
            float: right;
            margin-left: 0.5em;
            margin-bottom: 0;
        }

        img {
            width: 140px;
            max-width: none;
            float: none;
            @include breakpoint($sm){
                margin-right: 0;
                margin-top: 0;
                // margin-left: 0.5em;
            }
        }
    }
}

.field-name-field-news-category, .views-field-field-news-category {
    a {
        // color: inherit; // $wcm-dark-orange;
        // font-size: 14px;
        background: url(../images/tag.png) 0 0 no-repeat;
        background-size: 16px 16px;
        padding-left: 25px;
        position: relative;
    }
}

// .profile-bio {
.view-bio-btn {
    float: left;
    margin: 5px 0;
}

.profile-bio-content, .views-field-field-biographical-info {
    display: none;
    clear: both;
    padding-top: 10px;
}
// }

.view-profiles {
    h3 {
        margin-bottom: 10px;
        font-size: 30px;
    }
    .views-row {
        @include clearfix();
        margin-bottom: 20px;

        &.views-row-last {
            margin-bottom: 40px;
        }

        .col-md-3, .col-md-2 {
            padding-left: 0;
        }
        .col-md-9, .col-md-12, .col-md-10 {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .display-name {
        font-size: 20px;
        margin-bottom: 0;
        @include breakpoint($md) {
            margin-top: 0;
        }
    }
}

.pane-profiles-panel-pane-6 {
    h3:first-child {
        display: none;
    }
    .views-row {
        h3 {
            display: block;
        }
    }
}

.pane-profiles-panel-pane-11 {

  .view-filters {
    margin-bottom: 30px;
  }

  .view-content {
    margin-left: -20px;
    margin-right: -20px;

    @include breakpoint($sm) {
			display: flex;
			flex-wrap: wrap;
			&:before {
				display: block;
			}
		}
  }

  h3 {
    font-size: 20px;
    margin-bottom: 15px;
    clear: both;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .profile-list-item {

    @include breakpoint($sm) {
      display: flex;
      flex-direction: column;
    }

    margin: 0 0 30px;
    font-size: 13px;

    .display-name {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 15px;
    }

    .profile_headshot {
      margin-bottom: 5px;
      border-top: 8px solid $wcm-red;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease 0s;

      img {
        width: 100%;
        transition: all 0.3s ease 0s;
      }
    }

    .views-field-field-professional-title {
      margin-bottom: 5px;
    }
  }
}

img.panopoly-image-quarter {
    margin-right: 40px !important;
    margin-bottom: 30px !important;

    @include breakpoint( (max: 480px) ) {
        max-width: none !important;
        width: 100% !important;
    }
}

.caption.panopoly-image-quarter {
    @include breakpoint( (max: 480px) ) {
        max-width: none !important;
    }

    img.panopoly-image-quarter {
        margin-bottom: 20px !important;
    }
}

.front .hero-image--full {
    width: 100%;
    height: 175px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 0 20px;
    @include breakpoint($sm) {
        height: 300px;
    }
}

.localist-event {
    @include clearfix();
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 20px;
    padding-top: 20px;

    h2 {
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    img {
        margin-bottom: 15px;
        @include breakpoint($sm) {
            float: left;
            margin-right: 15px;
        }
    }
}

#main-content h3 a:empty {
    &:after {
        display: none;
    }
}

div.caption-left {
    @include breakpoint($md) {
        margin: 0px 20px 0px 0;
    }
}

.pane-bundle-spotlight {
    margin-bottom: 20px;
    @include breakpoint($sm) {
        // float: left;
        // margin-right: 30px;
    }
}

//NEWS AND AWARDS FILTER DROPDOWN WIDTH OVERRIDE

.form-item-published-year .select2-container{
    width: 120px !important;
}

.form-item-field-news-honors-awards-value .select2-container{
    width: 200px !important;
}

.form-item-field-news-category-tid .select2-container{
    width: 280px !important;
}

.view-news {
    .form-control {
        max-width: none;
    }

    .form-item-published-year, .form-item-date-filter-value-year {
        .select2-container {
            width: 120px !important;
        }
    }

    img.panopoly-image-quarter {
        margin-right: 0px !important;
        margin-bottom: 5px !important;
    }
}

.ctools-collapsible-container {
    .ctools-toggle {
        display: none;
    }
}
.ctools-collapsible-processed {
    .ctools-collapsible-handle h2 {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: $wcm-dark-orange;
        border: 1px solid $wcm-border-gray;
        border-width: 3px 0 0;
        cursor: pointer;
        margin: 0;
        padding: 15px 55px 15px 10px;
        font-weight: 600;

        &:hover {
            color: $wcm-bright-orange;
        }

        &:after {
            content: '-';
            width: 50px;
            display: block;
            position: absolute;
            top: 15px;
            right: 0;
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            border-left: 3px solid $wcm-border-gray;
        }
    }

    &.ctools-collapsed .ctools-collapsible-handle h2:after {
        content: '+';
    }
}

.pager--infinite-scroll {
    li a {
        border-radius: 20px;
        border: 4px solid $wcm-bright-orange;
        padding: 6px 20px;
        font-size: 13px;
        width: 220px;

        &:hover, &:focus {
            border-color: $wcm-red;
            background-color: $wcm-dark-orange;
            color: #fff;
        }
    }
}


table {
  table-layout: fixed;
}

.biblio-separator-bar {
  margin:1em 3.5em 1em 3.5em;
}

.biblio-entry {
    margin:1em 3.5em 1em 3.5em;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}
